const riskControlMenuEvent = {
    /**
     * 左侧菜单
     */
    'aladdin.risk_menu': 'RC List',
    'riskMenu.menuEvent': 'Project',
    'riskMenu.menuEvent.userId': 'User ID ',
    'riskMenu.menuEvent.orgId': 'Org ID ',
    'riskMenu.menuEvent.ip': 'IP Address',
    'riskMenu.menuEvent.imei': 'Device Identity',
    'riskMenu.menuEvent.phone': 'Phone Number',
    'riskMenu.menuEvent.pay': 'Payment Account',
    'riskMenu.menuEvent.toAddress': 'Address',
    'riskMenu.menuEvent.individualToAddress': 'User&Address',
    'riskMenu.menuEvent.userDevice': 'User&Device',
    'riskMenu.menuEvent.userIp': 'User&IP',
    'riskMenu.menuEvent.orgIp': 'Org&IP',
    'riskMenu.menuEvent.orgUA': 'Org&UserAgent',
    'riskMenu.menuEvent.email': 'Email',
    'riskMenu.menuEvent.selectAll': 'Select All',
    'riskMenu.menuEvent.largeWithdrawalRules': '默认大额提币规则',

    /**
     * 名单管理页面
     */
    'riskMenu.menuEvent.menuEventManage.form.createMenuEvent': 'Add New Project',
    'riskMenu.menuEvent.menuEventManage.form.walletType': 'Wallet Type',
    'riskMenu.menuEvent.menuEventManage.walletType.1': 'Common',
    'riskMenu.menuEvent.menuEventManage.walletType.2': 'Custody',
    'riskMenu.menuEvent.menuEventManage.walletType.3': 'MPC',

    /**
     * 子名单通用部分
     */
    'riskMenu.submenu.form.menuName': 'Project Name',
    'riskMenu.submenu.form.menuType': 'List Type',
    'riskMenu.submenu.form.menuStatus': 'Status',
    'riskMenu.submenu.form.menuStatus.all': 'All',
    'riskMenu.submenu.form.menuStatus.valid': 'Valid',
    'riskMenu.submenu.form.menuStatus.invalid': 'Invalid',
    'riskMenu.submenu.form.menuDesc': 'Remarks',
    'riskMenu.submenu.form.endTime': 'End At',
    'riskMenu.submenu.form.modifiedTime': 'Updated At',
    'riskMenu.submenu.form.creator': 'Updated By',
    'riskMenu.submenu.form.textarea.placeholder': 'Please separate with commas when adding more than one',
    'riskMenu.submenu.form.textarea.composite-placeholder':
        "The composite value should be connected by '_', and use commas to separate when adding more than one",
    'riskMenu.submenu.form.textarea.composite-error': 'The format of the content you entered is incorrect',
    'riskMenu.submenu.form.textarea.error': 'The format of the content you entered is incorrect',

    /**
     * 子名单：用户ID
     */
    'riskMenu.submenu.form.userId': 'User ID',
    'riskMenu.submenu.createSubmenuModel.title.userId': 'Create New User ID',

    /**
     * Organization id 管理页面
     */
    'riskMenu.submenu.form.orgId': 'Org ID',
    'riskMenu.submenu.createSubmenuModel.title.orgId': 'Create New Org ID',

    /**
     * IP 地址管理页面
     */
    'riskMenu.submenu.form.ip': 'IP Adress',
    'riskMenu.submenu.createSubmenuModel.title.ip': 'Create New IP Adress',

    /**
     * IMEI 移动设备识别码管理页面
     */
    'riskMenu.submenu.form.imei': 'Device Identity',
    'riskMenu.submenu.createSubmenuModel.title.imei': 'Create New Device Identity',

    /**
     * 支付账户管理页面
     */
    'riskMenu.submenu.form.pay': 'Payment Account',
    'riskMenu.submenu.createSubmenuModel.title.pay': 'Create New Payment Account',

    /**
     * 手机号管理页面
     */
    'riskMenu.submenu.form.phone': 'Phone Number',
    'riskMenu.submenu.createSubmenuModel.title.phone': 'Create New Phone Number',

    /**
     * 收款地址管理
     */
    'riskMenu.submenu.form.toAddress': 'Address',
    'riskMenu.submenu.createSubmenuModel.title.toAddress': 'Create New Address',

    /**
     * 用户-提币地址管理
     */
    'riskMenu.submenu.form.individualToAddress': 'User&Address',
    'riskMenu.submenu.createSubmenuModel.title.individualToAddress': 'Create New User&Address',

    /**
     * 用户-设备管理
     */
    'riskMenu.submenu.form.userDevice': 'User&Device',
    'riskMenu.submenu.createSubmenuModel.title.userDevice': 'Create New User&Device',

    /**
     * 用户-IP管理
     */
    'riskMenu.submenu.form.userIp': 'User&IP',
    'riskMenu.submenu.createSubmenuModel.title.userIp': 'Create New User&Device',

    /**
     * Org_IP管理
     */
    'riskMenu.submenu.form.orgIp': 'Org&IP',
    'riskMenu.submenu.createSubmenuModel.title.orgIp': 'Create New Org&IP',

    /**
     * Org_UA管理
     */
    'riskMenu.submenu.form.orgUA': 'Org&UA',
    'riskMenu.submenu.createSubmenuModel.title.orgUA': 'Create New Org&UA',

    /**
     * 邮箱管理
     */
    'riskMenu.submenu.form.email': 'Email',
    'riskMenu.submenu.createSubmenuModel.title.email': 'Cteate new Email',
};

export default riskControlMenuEvent;
